@use "src/variables.scss" as var;

.cls-app-panel {
    height: 100%;
}
.myrec-talon {
    margin: 0;
    padding: 0;
}
// .ngx-cc {
//     &__cookie-controls {
//         display: none !important;
//     }

//     @media screen and (max-width: 768px) {
//         &__modal {
//             max-width: 100vw !important;
//             overflow: hidden !important;
//             &__header {
//                 word-break: break-word;
//             }
//             &__header h1 {
//                 font-size: 2.2rem !important;
//             }
//         }
//         &__wrapper {
//             max-width: 100vw;
//         }
//     }
// }

@media (max-width: 768px) {
    .cls-app-panel {
        height: auto;
    }
    .refund-panel-class {
        max-width: unset !important;
        width: 100vw;
        height: 100vh;
    }
    html,
    body {
        overflow: visible !important;
        height: 100%;
        width: 100%;
        font-family: "Roboto", sans-serif;
        &::-webkit-scrollbar {
            background-color: rgba(231, 238, 252, 1);
            width: 6px;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(95, 172, 255, 1);
            width: 6px;
        }
    }

    .cls-btn-second {
        border: none !important;
    }
    .tooltipPanelTeleNotPay .tooltip-inner {
        max-width: 240px;
    }
    .ng-star-inserted .container.myrec-talons {
        max-width: 100%;
    }

    .dropdown-menu {
        box-shadow: 0px 4px 4px rgba(107, 129, 150, 0.25);
        border: 1px solid #ecf0f5;
    }
    .cls-btn-icon-down svg path,
    .cls-btn-icon-up svg path {
        fill: #a2bdcb !important;
    }

    .cls-btn-lk {
        &:not(:last-child) {
            margin-right: 16px;
        }
    }
    .cls-home {
        margin-left: 0px;
    }
    // .ng-star-inserted {
    //     position: relative;
    // }

    .cdk-overlay-connected-position-bounding-box {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        left: 0 !important;
        top: 0 !important;
        height: 100%;
        min-height: 100%;
    }

    .mdc-list {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        left: 0 !important;
        top: 0 !important;
        height: 100%;
        min-height: 100%;
    }

    .cls-menu-list {
        position: fixed !important;
        top: 0px !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }

    .mat-mdc-menu-panel {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        left: 0 !important;
        top: 0 !important;
        height: 100%;
        min-height: 100%;
    }

    .cdk-overlay-connected-position-bounding-box
        .mat-mdc-menu-panel
        .mat-mdc-menu-content {
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .cdk-overlay-connected-position-bounding-box
        .mat-mdc-menu-panel
        .mat-mdc-menu-content
        > button {
        font-weight: 400;
        font-size: 18px;
        color: #4f6785;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 5px;
    }
    .matMenu-wrapper {
        width: 80%;
    }
    .mat-mdc-menu-item .mat-icon svg path {
        fill: #a1b5cd;
    }
    .cls-btn-menu {
        z-index: 6969;
    }
    .row {
        margin-left: 0;
        margin-right: 0;
    }
    //календарь стили перенести в медиа по компонентам

    // .loading-group.cls-blok-body,
    // .app-calendar-events-wrap {
    //     height: 100% !important;
    //     padding-left: 0 !important;
    //     padding-right: 0 !important;
    // }
    .cls-app-panel {
        padding: 0 !important;
    }
    .cls-blok-body {
        padding-left: 20px !important;
    }

    .panel-item-talon {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .ng-star-inserted .container.myrec-talons {
        padding-left: 0;
        padding-right: 0;
    }
    .myrec-talons .panel-item-talon {
        padding-bottom: 4px;
        padding-top: 0 !important;
    }
    .cls-block-header span {
        display: none;
    }

    .btn.cls-btn-rec.ng-star-inserted {
        display: none;
    }
    .cls-block-header {
        padding-left: 0;
    }
    .panel-item-talon .talon-footer {
        display: none;
    }

    .ng-star-inserted .cls-block-header .btn {
        border: none;
        border-bottom: 4px solid transparent;
    }

    //popup запись к врачу
    .cdk-overlay-panel {
        max-width: 100% !important;
        max-height: 100% !important;

        // padding: 0 24px;
    }
    .ng-star-inserted .talon-content {
        width: 100% !important;
    }
    .cls-doc {
        height: auto !important;
    }

    .cls-doc-time {
        float: none;
    }

    .cls-ptp-footer {
        height: auto !important;
        min-height: auto !important;
        max-height: 100% !important;
    }
    .loading-group.cls-blok-body {
        height: auto !important;
        border-radius: 0 !important;
    }

    .cdk-overlay-container {
        z-index: 9999;
    }
    .call-calendar-mob--wrap {
        display: flex;
        width: 100%;
    }
    .call-calendar-mob--wrap .call-calendar-mob {
        width: 100%;
        background: #e9eeff;
        box-shadow: 0px 4px 4px #e2e7f8;
        outline: none;
        border: none;
        font-weight: 500;
        font-size: 16px;
        color: #9ca8d5;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
    }
    .popup-bg-white {
        background: #fff;
        padding: 20px 10px;
        box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
        border-radius: 5px;
    }

    div.mat-mdc-dialog-content {
        background: #fff;
    }

    .btn-icon-location {
        display: none;
    }
    .tooltip-inner {
        position: absolute;
        transform: translateX(-100%);
        background: #323232;
        text-align: center;
        top: 0;
        box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
        border-radius: 10px;
        color: #fff;
    }
    .cls-blok-body {
        padding-bottom: 0;
    }
    .mat-mdc-dialog-container {
        // position: fixed;
        // top: 0;
        // left: 0;
        background: initial;
        border-radius: 0 !important;
        // padding-top: 24px!important;
        // padding-bottom: 24px!important;
    }

    //календарь
    .app-myRec--inCalendar
        .myrec-talons
        .panel-item-talon
        .myrec-item-talon
        .talon-header,
    .app-myRec--inCalendar .myrec-talons .panel-item-talon .row {
        display: none !important;
    }
    .app-myRec--inCalendar .myrec-talons .panel-item-talon {
        display: none;
    }
    .app-myRec--inCalendar .myrec-talons .panel-item-talon:first-child {
        display: flex;
    }
    .app-myRec--inCalendar
        .myrec-talons
        .panel-item-talon
        .myrec-item-talon
        .myrec-component--bottom-inCalendar {
        display: flex;
    }

    //таблица в попапе реализованное
    .last-events--table table tr {
        display: flex;
        flex-direction: column;
    }

    .last-events--table table:first-child {
        display: none;
    }
    .last-events--table hr {
        display: none;
    }
    .last-events--table table tbody > *:not(:last-child) {
        padding-bottom: 25px;
    }
    .last-events--table table tbody tr td:last-child {
        font-weight: 400;
        font-size: 16px;
        color: #626262;
    }
    .tooltip .arrow {
        display: none !important;
    }
    .cls-icon-menu-item svg path {
        stroke: #becee2 !important;
    }
    .cls-icon-menu-item-fill svg path {
        fill: #becee2 !important;
    }
    ngb-modal-backdrop {
        z-index: 9999 !important;
    }
    .modal-open .modal {
        display: flex !important;
        align-items: center;
        justify-content: center;
        z-index: 9999 !important;
    }

    #alert-container.top-right {
        top: 90% !important;
        right: 0 !important;
        width: 100%;
        padding: 0 10px;
    }

    #alert-container.top-right .alert-success {
        box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
        border-radius: 10px;
        width: 100% !important;
        height: 54px;
        display: flex;
        padding-left: 1.5rem;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #e2e2e4 !important;
        background: #323232;
        background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.801 0.0516994C8.50558 0.35725 5.4504 1.90812 3.25909 4.38816C1.0675 6.86821 -0.0957616 10.0909 0.00617535 13.3989C0.108317 16.7068 1.46784 19.8512 3.80819 22.1917C6.14854 24.5321 9.29305 25.8917 12.6011 25.9938C15.9093 26.0957 19.1319 24.9324 21.6118 22.7408C24.0918 20.5493 25.6427 17.4944 25.9482 14.1989C26.1754 11.6571 25.6515 9.10452 24.4416 6.8577C23.2319 4.61067 21.3893 2.76806 19.1423 1.55836C16.8954 0.348445 14.3429 -0.175392 11.8011 0.0517549L11.801 0.0516994ZM19.983 10.0784L12.2781 17.7033C12.0468 17.9283 11.7369 18.0544 11.4143 18.0544C11.0917 18.0544 10.7819 17.9283 10.5506 17.7033L6.00918 13.3664C5.68959 13.0593 5.55808 12.6051 5.66389 12.175C5.76992 11.7446 6.0975 11.4037 6.52311 11.2803C6.94873 11.1568 7.40782 11.27 7.72739 11.5768L11.3928 15.0936L18.2525 8.31734C18.5695 8.01935 19.0197 7.90965 19.4382 8.0282C19.8569 8.14696 20.1825 8.4767 20.2959 8.89671C20.4095 9.31671 20.2942 9.76544 19.9923 10.0788L19.983 10.0784Z' fill='%2399E0A4'/%3E%3C/svg%3E%0A") !important;
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 5px;

        // Success alert text
        p {
            display: flex;
            align-items: center;
            padding: 1rem;
            margin: 0;
            height: 100%;
        }
    }

    /*
  #alert-container.top-right .alert-error {
    background: #323232;
    box-shadow: 0px 4px 4px rgba(198, 198, 198, 0.25);
    border-radius: 10px;
    width: 100%!important;
    height: 54px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: #E2E2E4!important;
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.801 0.0516994C8.50558 0.35725 5.4504 1.90812 3.25909 4.38816C1.0675 6.86821 -0.0957616 10.0909 0.00617535 13.3989C0.108317 16.7068 1.46784 19.8512 3.80819 22.1917C6.14854 24.5321 9.29305 25.8917 12.6011 25.9938C15.9093 26.0957 19.1319 24.9324 21.6118 22.7408C24.0918 20.5493 25.6427 17.4944 25.9482 14.1989C26.1754 11.6571 25.6515 9.10452 24.4416 6.8577C23.2319 4.61067 21.3893 2.76806 19.1423 1.55836C16.8954 0.348445 14.3429 -0.175392 11.8011 0.0517549L11.801 0.0516994ZM19.983 10.0784L12.2781 17.7033C12.0468 17.9283 11.7369 18.0544 11.4143 18.0544C11.0917 18.0544 10.7819 17.9283 10.5506 17.7033L6.00918 13.3664C5.68959 13.0593 5.55808 12.6051 5.66389 12.175C5.76992 11.7446 6.0975 11.4037 6.52311 11.2803C6.94873 11.1568 7.40782 11.27 7.72739 11.5768L11.3928 15.0936L18.2525 8.31734C18.5695 8.01935 19.0197 7.90965 19.4382 8.0282C19.8569 8.14696 20.1825 8.4767 20.2959 8.89671C20.4095 9.31671 20.2942 9.76544 19.9923 10.0788L19.983 10.0784Z' fill='%2399E0A4'/%3E%3C/svg%3E%0A")!important;
  }
  */
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .aboniment-wrapper
        .mat-mdc-form-field-type-mat-select:not(.mat-mdc-text-field-disabled)
        .mat--mdc-form-field-flex {
        border-bottom: none;
        background: #ecf0f5;
        border-radius: 10px;
        font-weight: 500;
        font-size: 16px;
        color: #5f5f5f;
    }
    .aboniment-wrapper .cls-app-panel {
        box-shadow: none !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .matformfieldSelect .mat-form-field-underline {
        display: none !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-panel-wrap {
        // margin-top: 100px!important;
        background: transparent;
        // height: 100%;
        // padding-left: 11px;
        // padding-right: 11px;
    }
    .mat-mdc-select-panel {
        min-width: 100% !important;
    }

    .mat-mdc-select-panel:not([class*="mat-elevation-z"]) {
        background: #ffffff;
        box-shadow: 0px 0px 24px rgba(209, 221, 237, 0.85);
        border-radius: 5px;
    }

    .payModalDialog {
        width: 100%;
        height: 100%;
    }
    //   .cal-dr-header-mount {
    //     font-weight: 500;
    //     font-size: 22px;
    //     color: rgba(29, 115, 217, 0.9);
    // }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-appearance-legacy .mat-mdc-text-field-underline {
        background-color: #cccccc;
    }
    .app-payments-feed-page-template .feed__footer {
        background: #f5fbff;
    }
    //   .cdk-overlay-backdrop  .cdk-overlay-pane {
    //     width: 100%!important;
    //     top: 0px!important;
    //     height: 100%!important;
    //     left: 0!important;
    //     transform: translateX(0) translateY(0)!important;
    // }
    // .ng-trigger-transformPanel.mat-mdc-select-panel {
    // margin-top: calc(100vh - 90vh);
    // }
    .cdk-overlay-backdrop .cdk-overlay-pane {
        position: absolute;
        pointer-events: auto;
        box-sizing: border-box;
        z-index: 1000;
        display: flex;
        max-width: 100%;
        max-height: 100%;
        transform: none !important;
        margin-top: 22px;
    }
    .btn:focus {
        box-shadow: none;
    }

    .finance-page--anchor .page-anchor_blue {
        background: none !important;
        font-weight: 400 !important;
        font-size: 14px;
        color: #a9aebd !important;
        padding-top: 16px !important;
        padding-bottom: 16px !important;
    }
    .finance-page--anchor .page-anchor_blue.page-anchor_hidden {
        display: none;
    }

    //mat input form
    .mat-mdc-form-field-hint {
        font-size: 14px;
        color: #c6c6c6 !important;
    }
    .mat-mdc-form-field.mat-focused {
        color: #303337;
    }
    .mat-focused .mat-mdc-input-element::placeholder {
        color: #cccccc !important;
    }
    .mat-form-field-appearance-outline.mat-focused .mat-mdc-input-element {
        color: #cccccc !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-focused .mdc-line-ripple {
        background-color: #2d05e9;
    }

    // mat-label focused style
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field.mat-focused .mat-form-field-label {
        color: #cccccc !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label-wrapper {
        background-color: transparent !important;
    }
    .finanse-header--fixed .cls-position-relative {
        box-shadow: 0px 4px 4px #d1dded !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-appearance-fill .mat-mdc-form-field-flex {
        // height: 56px;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 18px !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
        transform: translateY(0) !important;
    }

    .cdk-overlay-connected-position-bounding-box .mat-mdc-select-panel {
        max-height: 100%;
        overflow: scroll;
    }
    // .cdk-overlay-connected-position-bounding-box .mat-select-panel-wrap {
    //   margin-top: 0px!important;
    // }
    .content--over .no-more-content {
        margin-bottom: 130px;
        font-weight: 400 !important;
        font-size: 16px !important;
        color: #b2c4d0 !important;
        background: inherit !important;
    }
    .app-payments-feed-page-template {
        z-index: 0;
    }
    // .cdk-overlay-connected-position-bounding-box {
    //   top: 100px!important;
    // }

    // .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    //     top: 0px !important;
    //     left: 0 !important;
    //     width: 100% !important;
    //     height: 100% !important;
    // }
    // .mat-select-panel-wrap {
    //   top: 100px!important;
    // }
    // .cdk-overlay-container {
    //   top: 100px!important;
    // }
    .inSelect {
        margin-bottom: 15px;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value {
        font-weight: 500;
        font-size: 16px;
        color: #5f5f5f;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-arrow-wrapper .mat-select-arrow {
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        background: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6317 0.355291C13.5157 0.242666 13.3779 0.153312 13.2262 0.0923468C13.0745 0.0313812 12.9119 4.75609e-08 12.7477 5.47388e-08C12.5835 6.19168e-08 12.4209 0.0313813 12.2692 0.0923469C12.1175 0.153312 11.9798 0.242666 11.8638 0.355291L6.99891 5.06911L2.13404 0.355292C1.89961 0.128132 1.58164 0.000514971 1.2501 0.000514985C0.91855 0.000515 0.60058 0.128132 0.366142 0.355292C0.131704 0.582452 3.8927e-08 0.890546 5.29694e-08 1.2118C6.70118e-08 1.53305 0.131704 1.84115 0.366142 2.06831L6.12123 7.64471C6.23722 7.75734 6.37501 7.84669 6.52669 7.90766C6.67837 7.96862 6.84097 8 7.00518 8C7.16939 8 7.33199 7.96862 7.48367 7.90766C7.63535 7.84669 7.77313 7.75734 7.88913 7.64471L13.6442 2.0683C14.1207 1.60664 14.1207 0.829104 13.6317 0.355291Z' fill='%23BEBEBE'/%3E%3C/svg%3E%0A") !important;
        border-image-repeat: space !important;
        background-repeat: no-repeat;
        width: 14px;
        height: 8px;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-required-marker {
        display: none;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-appearance-legacy .mat-form-field-ripple {
        height: 1px !important;
    }
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .cls-block-pay-anime--bottom .container .mat-form-field-flex,
    .over--input .mat-form-field-flex {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
    .cls-block-pay-anime--bottom .container mat-hint,
    .over--input mat-hint {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .cdk-global-scrollblock {
        position: static;
        overflow: hidden !important;
    }

    .panel--fixed__slide-body .tooltip-inner {
        top: -34px;
        transform: translateX(-50%);
        font-weight: 400;
        color: #5c718b;
        height: 34px;
        min-width: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 18px;
        background: #e1e7f2;
        border-radius: 5px;
        &:before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            position: absolute;
            bottom: -8px;
            border-style: solid;
            border-width: 8px 13px 0 13px;
            border-color: #e1e7f2 transparent transparent transparent;
        }
    }
}
html {
    scroll-behavior: smooth !important;
}
.mobile-modal__wrapper {
    background: #fff;
}
.mat-mdc-select-panel-wrap .mat-select-panel {
    height: 100%;
}
.mat-mdc-option.mat-active {
    background: none;
}
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-panel-wrap .mat-mdc-option-ripple {
    display: none !important;
}
.mat-mdc-select-panel
    .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple) {
    background: #ecf0f5;
}
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.myFormAppereacnce-select .mat-select-value {
    max-width: 100% !important;
}
.panel--fixed .cls-app-panel {
    box-shadow: none !important;
}

.result-item-name .tooltip {
    top: 60px !important;
    left: auto !important;
    right: 0;
    min-width: 200px;
}

.result-item-name .tooltip-inner {
    width: auto;
    top: -48px;
    left: -19px;
}

.result-item-name .tooltip-inner:before {
    top: -8px;
    left: 4px;
    border-width: 0 13px 8px 13px;
    border-color: transparent transparent #e1e7f2 transparent;
}

.cls-modal-mobail-talon {
    top: 0;
    left: 0;
    min-height: 100vh;
    padding: 0;
    background: #fff;
    overflow: auto;
    position: relative;
    width: 100vw;
}

// -----====== MATERIAL v15 UPGRADE STYLES=========------------

.modal-dialog {
    z-index: 314134134134 !important;
    margin: 0;
    padding: 0 24px;
    max-width: none;
}
.mat-icon {
    overflow: initial !important;
}

// Fix teleconf modal
.modal-window {
    z-index: 69420 !important;
}

// input background
.mdc-text-field,
.mdc-text-field--focused,
.mat-mdc-form-field-focus-overlay {
    background-color: white !important;
}

// input label translate override
.mdc-text-field .mdc-floating-label {
    transform: translateY(0%) !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-106%) scale(0.75) !important;
}

//Mat icon prefix
.mat-mdc-form-field-icon-prefix {
    align-self: auto !important;
}

// Datepicker icon
input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
    display: block;
}

.cls-block-rec-save {
    font-size: small;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mdc-form-field {
        display: flex !important;
        align-items: center;

        & label {
            margin-bottom: 0 !important;
        }
    }
}

#page-prev,
#page-next {
    border-radius: 20px 0px 0px 20px;
    width: 1rem;
}

.mat-mdc-menu-panel {
    max-width: none !important;
}

// Display option for promo carousel inner text
.visually-hidden {
    display: none;
}

// Active tab indicators
.carousel-indicators[role="tablist"] {
    & button {
        background-color: white;
        border: 1px solid grey;
        transition: background-color 0.6s ease-in;
    }

    & button[aria-selected="true"] {
        background-color: grey;
    }
}

.modal-open .modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 9999 !important;
}

body.modal-open {
    overflow: hidden !important;
    position: fixed;
}

.dicom-modal__backdrop {
    background-color: black;
    opacity: 0.69 !important;
}

ul {
    margin: 0;
    padding: 0;
}

.loader-rotate {
    animation: rotateLoader infinite linear 1s;
}

@keyframes rotateLoader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.cls-menu-list .mdc-list-item__primary-text {
    width: 300px;
}

// .cdk-overlay-pane {
//     max-height: 80% !important;
// }

.cdk-overlay-pane {
    max-height: 100% !important;
    overflow-y: auto;
    @include var.media(768) {
        // max-height: 80% !important;
    }
}

::-webkit-scrollbar {
    background-color: rgba(231, 238, 252, 1);
    width: 6px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(95, 172, 255, 1);
    width: 6px;
}
