/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "calendary.css";

$color-btn-hover: #435b78;
$color-btn-text-hover: #ffff;

$color-mat-icon: #a9b3c1; // Цвет иконки

$color-toop: #e1e7f2; // цвет подсказки
/*
$color-btn : #E4EEFC;
$color-btn-text : #8AA4C3;
*/
$color-btn: #435b78;
$color-btn-text: #ffff;

$color-btn-disable: #bac8da;

:root {
    --vh: 1vh;
}

html,
body {
    height: 100%;
    // background-color: #f5fbff;
    background-color: #f3f6fa;
    //overflow: hidden;
    /*  overflow-x: auto;*/
    /*
  background-color: aliceblue;
  */
}

@media (min-width: 1600px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1440px;
    }
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Кнопка */
/* Не активная кнопка */
.cls-btn:disabled {
    color: #fff;
    background-color: $color-btn-disable;
    border-color: $color-btn-disable;
}

/* Активная кнопка */
.cls-btn {
    border-radius: 23px;
    margin-top: 20px;
    color: $color-btn-text;
    background-color: $color-btn;
    border-color: $color-btn;
}

/* при наведении */
.cls-btn:hover {
    color: $color-btn-text;
}

p {
    margin-top: 0px;
    margin-bottom: 0px;
}

/* Лоадер */
/* Группа */
.loading-group {
    position: relative;
    /*
  overflow: hidden; 111 отдельно вынести в класс, чтобы лоадинг не вылазила за пределы.
  */
    /*
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  */
}

.loading {
    position: absolute;
    z-index: 996;
    /*1010; */
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    opacity: 0.9;
}

.loading > img {
    position: absolute;
    /*
  right: 42%;
  top: 49%;
  */

    width: 258px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading > p {
    position: absolute;
    right: 30%;
    top: 49%;
    width: 50%;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}

/* подсказка */
.tooltip.show {
    opacity: 1;
}

/*.tooltipPanel .tooltip-inner{*/
.tooltip-inner {
    background-color: $color-toop;
    color: #435b78;
}

// Подсказча, что нет оплаты на телевидео
.tooltipPanelTeleNotPay .tooltip-inner {
    max-width: 225px;
}

/* Стрелочка */
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
    border-left-color: $color-toop;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
    border-right-color: $color-toop;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $color-toop;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: $color-toop;
}

/* PAGINATION */
.page-link {
    border: none;
    color: #435b79;
    /*
  min-width: 42px;
  */
    text-align: center;
    width: 41px;
    height: 41px;
    padding-top: 10px;
}

.page-item.active .page-link {
    background-color: #435b78;
    border-radius: 22px;
}

.pagination {
    float: right;
}

/* Modal */
.mat-mdc-dialog-container {
    border-radius: 10px !important;
    padding: 0 !important;
}

.mdc-menu-surface.mat-mdc-autocomplete-panel {
    width: 100%;
    max-height: 100% !important;
    // max-height: 1206px !important;
    position: static;
    visibility: hidden;
    transform-origin: center top;
    margin: 0;
    padding: 8px 0;
    list-style-type: none;
}

.cdk-overlay-pane.no-border-radius {
    border-radius: 0 !important;

    .mat-mdc-dialog-container {
        border-radius: 0 !important;
    }
}

/* Заголовок */
.mdc-dialog__title {
    // background-color: #435b78 !important;
    // color: white !important;
    // font-size: 14px !important;
    // padding-left: 5px !important;
    // padding-right: 5px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    // flex-direction: row-reverse;
}

.mdc-dialog__title::before {
    content: none !important;
}

div.mat-mdc-dialog-content {
    margin: 0;
    // max-width: 480px;
    max-width: fit-content;
    max-height: 93vh;
}

/* Стандартный цвет иконки */
.cls-icon-color svg {
    color: #8195a8;
    fill: #8195a8;
}

.cls-icon-krest svg {
    color: white;
    fill: white;
}

.cls-mat-icon {
    color: $color-mat-icon;
}

.cls-mat-icon:hover {
    color: $color-btn-hover;
}

/* Кнопки другие - не основные */

/* Активная кнопка */
.cls-btn-second {
    border-radius: 23px;
    margin-top: 20px;
    color: #91959b;
    background-color: #e4eefc;
    border-color: #e4eefc;
}

/* Не активная */
.cls-btn-second:disabled {
    color: #c1c6cc;
    background-color: #f2f7fe;
    border-color: #f2f7fe;
}

/* при наведении */
.cls-btn-second:hover {
    color: #888d95;
    background-color: #ccdaed;
    border-color: #ccdaed;
}

/* при наведении */
.cls-btn-second:active {
    color: white;
}

.cls-panel {
    min-width: 350px;
    min-height: 130px;
    border: 3px solid;
    border-radius: 10px;
    border-color: white;
    background-color: white;
    color: #4f6785;
    /*
  margin: 10px;
  */
    font-size: 26px;
    font-weight: 500;
}

/* TABLE */

/*  Заголовок таблици */
.cls-header-table {
    color: #c5c5c5;
    font-size: 14px;
    font-weight: normal;
}

/* Кнопки фильтров */
.cls-btn-filter {
    background-color: #e1e6ec;
}

/* Активная кнопка (выбрана)*/
.cls-btn-filter-active {
    color: white;
    background-color: #435b78;
    border-color: #435b78;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: #f1f7ff;
}

// Блок заголовка
.cls-block-header {
    /*
  padding-top: 50px;
  padding-left: 50px;
  */
    grid-area: header;
    padding: 0;
    margin-top: 20px;
}

.cls-block-header span {
    color: #292b2c;
    font-size: 18px;
}

.cls-block-header button {
    margin-left: 10px;
    margin-top: 0px;
}

// Блок тела

/* Material SELECT */
.mat-mdc-form-field {
    width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-fill .mat-mdc-form-field-flex {
    background-color: white;
}

.mat-mdc-option.mat-active {
    background-color: #ecf0f5;
    color: rgb(67 91 120);
}

.mat-mdc-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
    background-color: #ecf0f5;
}

/* *********************************/

.cls-td-yeer {
    color: #d6c1b7;
    text-align: center;
    background-color: #fff8f5;
    font-weight: 500;
    font-size: 14px;
}

// Стиль нет записей
.cls-not-rec {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #82e2d1;
    font-size: 1.5rem;
    padding-bottom: 10px;
    font-weight: 500;
}

.cls-position-relative {
    position: relative;
}

.cls-status-order {
    mat-icon {
        width: 9px;
        margin-right: 5px;
    }
}

.cls-color-status-order1 {
    /* В работе - N ожидает обработки*/
    color: #6b46d4;

    mat-icon {
        fill: #6b46d4;
    }

    span {
        color: #6b46d4;
    }
}

.cls-color-status-order2 {
    /*  - X */
    mat-icon {
        fill: #388b50;
    }

    span {
        color: #388b50;
    }
}

.cls-color-status-order3,
.cls-color-status-orderF {
    /* Выполнен*/
    mat-icon {
        fill: #057700;
    }

    span {
        color: #057700;
    }
}

.cls-color-status-order4 {
    color: #05b0f8;

    mat-icon {
        fill: #05b0f8;
    }

    span {
        color: #05b0f8;
    }
}

.cls-color-status-orderW {
    /* Частично выполнен - W*/
    mat-icon {
        fill: #6ebb92;
    }

    span {
        color: #6ebb92;
    }
}

.cls-cp {
    cursor: pointer;
}

.mr0 {
    margin-right: 0 !important;
}

.cls-panel-menu:hover {
    border: solid 4px #00408840;
}

.cls-table-td-price {
    text-align: right;
}

.cls-table-td-ser-price {
    text-align: right;
    color: #989898;
}

// На оплату
.cls-table-td-priceToPay {
    // color: #3AD0FF;
    cursor: pointer;
}

.cls-login-form {
    padding: 50px 0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* ссылка - Не помню пароль */
.cls-go-recovery {
    float: right;
}

// __<<ngM2ThemingMigrationEscapedComment41>>__
// .cls-icon-is-online-pay {
//     margin-right: 0 !important;
//     color: #ef7f7f;
//     right: 0;
//     position: absolute;
//     height: 100% !important;
// }

// __<<ngM2ThemingMigrationEscapedComment42>>__
// .cls-icon-is-online-pay-selected {
//     margin-right: 0 !important;
//     color: #ef7f7f;
//     right: 20px;
//     position: absolute;
//     height: 100% !important;
// }

.split-lines {
    /*
  background: url('./assets/img/smey_split.png') no-repeat;
  */
    background: url("./assets/img/smey_split.png");
    background-repeat: repeat-y;
}

.mb-25 {
    margin-bottom: 25px;
}

// Запрет выделения
.unselectable {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  not supported by any browser */
}

/* Блок разделяет на 3 части: Header, Body, Footer */
.display-grid-3 {
    display: grid;
    grid-template: 50px 1fr 50px/1fr;
    height: 100%;
}

.table-w100 {
    display: inline-table;
    width: 100%;
}

.app {
    &__calendar-btn {
        position: fixed;
        z-index: 200;
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 30px;
        bottom: 75px;
        background-color: #bdcaf6;
        border-radius: 50%;
        box-shadow: 0 0 8px rgba(149, 158, 190, 0.25);
        cursor: pointer;
        color: #fff;
        outline: none;
        border: none;

        &:active {
            background-color: #9aabe9;
        }

        img {
            user-select: none;
        }
    }

    &__mobile-page {
        height: calc(var(--vh) * 100 - 80px);
    }
}

.filter {
    .mat-mdc-select-trigger {
        padding: 10px 26px;
    }
}

.filter__dropdown {
    background-color: rgba(247, 247, 249, 1);
    border-radius: 10px !important;
    margin-top: 0.5rem !important;

    &[aria-multiselectable="false"] {
        mat-pseudo-checkbox {
            display: none;
        }
    }
}

.filter__option {
    &:hover {
        background-color: rgba(236, 240, 245, 1);
    }

    & .mdc-list-item__primary-text {
        width: 100%;
    }

    mat-pseudo-checkbox {
        display: none !important;
    }
}

//импорт медиа
@import "@styles.scss";
