.cal-table{
  width: 100%;
}

.cal-header{
  text-align: center;
  font-size: 18px;
}
.cal-header-mount{

}
.cal-header-year{
  margin-left: 3px;
  font-weight: bold;
}


.cal-h{
  max-width: 38px;
  text-align: center;
  color: #B9B9B9;
  font-weight: normal;
}

.cal-row{
  max-width: 38px;
  height: 36px;
  text-align: center;
}

.day-not-activ{
  color: white;
  /*
  color: #8AA4C3!important;
  */
  /*
  color: white;
  */
}

.day-activ{
  cursor: pointer;
  position: relative;
}

.day-activ:hover{
  color: #212529;
  background-color: #F1F7FF;
  border-radius: 28px;
}

mat-icon.cal-point {
  color: #82e2d1;
  position: absolute;
  width: 11px;
  right: 0px;
  top: -10px;
}

.day-selected:hover,
.day-selected {
  background-color: #9277ff;
  border-radius: 28px;
  color: white;
}

/*  Текущий день  */
.cls-dr-courrent-day {
 background-color: #C7BFE9;
 border-radius: 18px;
 color: white!important;
}

.cls-dr-courrent-day:hover {
  background-color: #6A4ED8;
}
